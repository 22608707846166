import { OptionsType } from '../types/constantDataType'

export const monthNameToNumber: { [key: string]: number } = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
}

export const monthNumberToName: { [key: number]: string } = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
export const monthsSmallForm = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
export const daysList = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
export const formatDate = (time: string) => {
  const originalDate = new Date(time)
  const creationDate = `${daysList[originalDate.getUTCDay()]}, ${months[originalDate.getUTCMonth()]} ${originalDate.getUTCDate()}, ${originalDate.getUTCFullYear()}`
  return creationDate
}

export const monthToNumber = (monthName: string): string => {
  const months: { [key: string]: number } = {
    january: 1,
    february: 2,
    march: 3,
    april: 4,
    may: 5,
    june: 6,
    july: 7,
    august: 8,
    september: 9,
    october: 10,
    november: 11,
    december: 12,
  }
  const normalizedMonthName = monthName?.toLowerCase()

  return String(months[normalizedMonthName])
}

export const reorderMonths = (): string[] => {
  const currentMonth = new Date().toLocaleString('default', { month: 'short' })
  const currentMonthIndex = monthsSmallForm.indexOf(currentMonth?.slice(0, 3))

  if (currentMonthIndex === -1) return monthsSmallForm

  const reorderedMonths = [
    ...monthsSmallForm.slice(currentMonthIndex + 1),
    ...monthsSmallForm.slice(0, currentMonthIndex + 1),
  ]

  return reorderedMonths
}

export const monthsList: OptionsType[] = [
  { id: 1, name: 'Jan' },
  { id: 2, name: 'Feb' },
  { id: 3, name: 'Mar' },
  { id: 4, name: 'Apr' },
  { id: 5, name: 'May' },
  { id: 6, name: 'Jun' },
  { id: 7, name: 'Jul' },
  { id: 8, name: 'Aug' },
  { id: 9, name: 'Sep' },
  { id: 10, name: 'Oct' },
  { id: 11, name: 'Nov' },
  { id: 12, name: 'Dec' },
]
