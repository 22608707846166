import { monthNameToNumber } from '@/app/utils/numberToMonth'
import { CommonFilterType } from '../globalRedux/filter/commonFilter'
import { CompanyFilterState } from '../globalRedux/filter/companyFilter'
import { NewsFilterState } from '../globalRedux/filter/newsFilter'
import { ProductState } from '../globalRedux/filter/productFilter'
import { EventFilterState } from '../globalRedux/filter/eventFilter'

export const companySortOptions: Record<string, string> = {
  'Latest Company': 'updated_at',
  'Company Name': 'company_name.normalized',
}
export const productSortOptions: Record<string, string> = {
  'Publish Date': 'publication_date',
  'Importer Country': 'country.keyword',
  'Product Name': 'product_name.keyword',
}
export const newsSortOptions: Record<string, string> = {
  'Publish Date': 'publication_date',
  Title: 'title.normalized',
}
export const eventSortOptions: Record<string, string> = {
  Upcoming: 'upcoming',
  Oldest: 'oldest',
}
export const map_sector: Record<string, string> = {
  'Fruit & Vegetable': 'AF Production',
  'Pre - Post Harvest': 'AF Pre & Post Harvest',
  'Transport / Logistics': 'AF Transports',
  'Retail Chains': 'AF Retail Chains',
}

export const formatNewsPayload = (
  newsFilter: NewsFilterState,
  commonFilter: CommonFilterType
) => {
  const filter: { field: string; value: string[] | number[] }[] = []
  filter.push({
    field: 'country',
    value: commonFilter?.companyLocation.map((item) => item?.name),
  })
  filter.push({ field: 'criteria', value: newsFilter.criterias })
  filter.push({ field: 'languages', value: [newsFilter?.language] })
  filter.push({
    field: 'region',
    value: commonFilter?.companyLocationContinents?.map((item) => item.name),
  })
  const sort = [
    {
      field: newsSortOptions[newsFilter?.selectedNewsSortOption],
      value: { order: newsFilter?.order },
    },
  ]

  return {
    match: newsFilter.searchTerm,
    filter: filter,
    sort: sort,
    facet: ['activites_english', 'region'],
  }
}

export const formatCompanyFilterData = (
  companyFilterData: CompanyFilterState,
  commonFilter: CommonFilterType
) => {
  const filter: { field: string; value: string[] | number[] | string }[] = []
  if (companyFilterData.sector == 'Fruit & Vegetable') {
    filter.push({ field: 'sector', value: ['AF Production'] })
    filter.push({
      field: 'activites_english',
      value: companyFilterData.fvSelectedActivity.map((item) => item),
    })
    filter.push({
      field: 'products_english',
      value: companyFilterData?.companySelectedProducts?.map(
        (item) => item.name
      ),
    })
    if (companyFilterData.cultivationType?.length > 0) {
      if (
        companyFilterData?.companySelectedProducts?.length > 0 &&
        companyFilterData.cultivationType?.length === 1
      ) {
        filter.push({
          field: 'product_types',
          value: companyFilterData?.companySelectedProducts?.map(
            (item) => `${item.name}|${companyFilterData.cultivationType}`
          ),
        })
      } else if (companyFilterData.cultivationType?.length !== 2) {
        filter.push({
          field: 'product_types',
          value: companyFilterData.cultivationType,
        })
      }
    }
  } else if (companyFilterData.sector == 'Pre - Post Harvest') {
    filter.push({ field: 'sector', value: ['AF Pre & Post Harvest'] })
    filter.push({
      field: 'products_english',
      value: companyFilterData?.pphSelectedProducts?.map((item) => item.name),
    })
    filter.push({
      field: 'product_family',
      value: companyFilterData.ppfSelectedFamily,
    })
    if (companyFilterData.cultivationType?.length > 0) {
      if (
        companyFilterData?.pphSelectedProducts?.length > 0 &&
        companyFilterData.cultivationType?.length === 1
      ) {
        filter.push({
          field: 'product_types',
          value: companyFilterData?.pphSelectedProducts?.map(
            (item) => `${item.name}|${companyFilterData.cultivationType}`
          ),
        })
      } else if (companyFilterData.cultivationType?.length !== 2) {
        filter.push({
          field: 'product_types',
          value: companyFilterData.cultivationType,
        })
      }
    }
  } else if (companyFilterData.sector == 'Transport / Logistics') {
    filter.push({ field: 'sector', value: ['AF Transports'] })
    filter.push({
      field: 'products_english',
      value: companyFilterData?.tlSelectedProducts?.map((item) => item.name),
    })
    if (companyFilterData.cultivationType?.length > 0) {
      if (
        companyFilterData?.tlSelectedProducts?.length > 0 &&
        companyFilterData.cultivationType?.length === 1
      ) {
        filter.push({
          field: 'product_types',
          value: companyFilterData?.tlSelectedProducts?.map(
            (item) => `${item.name}|${companyFilterData.cultivationType}`
          ),
        })
      } else if (companyFilterData.cultivationType?.length !== 2) {
        filter.push({
          field: 'product_types',
          value: companyFilterData.cultivationType,
        })
      }
    }
  } else if (companyFilterData.sector == 'Retail Chains') {
    filter.push({ field: 'sector', value: ['AF Retail Chains'] })
  }
  filter.push({
    field: 'country_name',
    value: commonFilter?.companyLocation?.map((item) => item.name) ?? [],
  })
  filter.push({
    field: 'region',
    value: commonFilter?.companyLocationContinents?.map((item) => item.name),
  })

  const sort = [
    {
      field: companySortOptions[companyFilterData?.companySelectedSortOption],
      value: { order: companyFilterData?.order },
    },
  ]

  return {
    match: companyFilterData.searchTerm,
    filter: filter,
    sort: sort,
    facet:
      companyFilterData.sector == 'Retail Chains'
        ? ['activites_english', 'region']
        : ['activites_english', 'region', 'product_family'],
  }
}

export const formatEventFilterData = (
  eventFilter: EventFilterState,
  commonFilter?: CommonFilterType
) => {
  const filter: {
    field: string
    value: string[] | number[] | undefined | Date
  }[] = []

  filter.push({
    field: 'country',
    value: commonFilter?.eventLocation?.map((item) => item?.name),
  })

  filter.push({
    field: 'region',
    value: commonFilter?.eventLocationContinents?.map((item) => item?.name),
  })

  if (eventFilter?.month) {
    filter.push({
      field: 'event_months',
      value: [monthNameToNumber[eventFilter?.month]],
    })
  }
  const sort = [
    {
      field: 'start_date',
      value: { order: eventFilter?.order },
    },
  ]
  return {
    match: eventFilter?.searchTerm || '',
    filter: filter,
    sort: sort,
    order: eventFilter?.selectedEventSortOption?.toLowerCase() || 'upcoming',
    facet: [],
  }
}

export const formatProductFilterData = (
  productsFilter: ProductState,
  commonFilter?: CommonFilterType
) => {
  const filter: { field: string; value: string[] | number[] | string }[] = []
  if (productsFilter.sector == 'Fruit & Vegetable') {
    filter.push({ field: 'sector', value: ['AF Production'] })
    filter.push({
      field: 'activities',
      value: productsFilter.productFruitVegetableActivity,
    })
    filter.push({
      field: 'cultivation',
      value: productsFilter.cultivationType,
    })
    filter.push({
      field: 'product_name',
      value: productsFilter.productFilterSelectedProducts.map(
        (item) => item.name
      ),
    })
    filter.push({
      field: 'product_origins',
      value: commonFilter?.productLocation?.map((item) => item?.name) ?? [],
    })
    filter.push({
      field: 'product_region',
      value:
        commonFilter?.productLocationContients?.map((item) => item?.name) ?? [],
    })
    if (productsFilter.productFVAvailability) {
      filter.push({
        field: 'availability',
        value: [monthNameToNumber[productsFilter.productFVAvailability]],
      })
    }
  } else if (productsFilter.sector == 'Pre - Post Harvest') {
    filter.push({ field: 'sector', value: ['AF Pre & Post Harvest'] })
    filter.push({
      field: 'product_name',
      value: productsFilter.productFilterSelectedProducts.map(
        (item) => item.name
      ),
    })

    filter.push({
      field: 'product_family',
      value: productsFilter.productPpHSelectedFamily,
    })
  } else if (productsFilter.sector == 'Transport / Logistics') {
    filter.push({ field: 'sector', value: ['AF Transports'] })
  }
  filter.push({
    field: 'company_location',
    value: commonFilter?.companyLocation?.map((item) => item?.name) ?? [],
  })
  filter.push({
    field: 'region',
    value:
      commonFilter?.companyLocationContinents?.map((item) => item.name) ?? [],
  })

  const sort = [
    {
      field: productSortOptions[productsFilter?.productSelectedSortOption],
      value: { order: productsFilter?.order },
    },
  ]

  return {
    filter: filter,
    sort: sort,
    match: productsFilter.searchTerm,
    facet: ['company_location', 'activities', 'product_family'],
  }
}
