import {
  formatCompanyFilterData,
  formatEventFilterData,
  formatNewsPayload,
  formatProductFilterData,
} from '@/app/utils/formatSearchData'
import { CompanyFilterState } from '../filter/companyFilter'
import { NewsFilterState } from '../filter/newsFilter'
import { ProductState } from '../filter/productFilter'
import { CommonFilterType } from './../filter/commonFilter'
import { apiSlice } from './apiSlice'
import { EventFilterState } from '../filter/eventFilter'
export const searchSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSearch: builder.query({
      query: (searchProps: {
        searchTerm: string
        lang: string | string[]
      }) => ({
        url: `/search/all?search_term=${searchProps.searchTerm}`,
        method: 'GET',
      }),
      transformResponse: async (responseData) => {
        return responseData
      },
    }),
    getCompanySearch: builder.query({
      query: ({
        companyFilter,
        commonFilter,
      }: {
        companyFilter: CompanyFilterState
        commonFilter: CommonFilterType
      }) => ({
        url: `/search/companies?page=${companyFilter.pageNo}`,
        method: 'POST',
        body: formatCompanyFilterData(companyFilter, commonFilter),
      }),
      transformResponse: async (responseData) => {
        return responseData
      },
    }),
    getProductSearch: builder.query({
      query: ({
        productsFilter,

        commonFilter,
      }: {
        productsFilter: ProductState
        commonFilter: CommonFilterType
      }) => ({
        url: `/search/products?page=${productsFilter.pageNo}`,
        method: 'POST',
        body: formatProductFilterData(productsFilter, commonFilter),
      }),
      transformResponse: async (responseData) => {
        return responseData
      },
    }),
    getBuyerAlerts: builder.query({
      query: ({
        productsFilter,
        commonFilter,
      }: {
        productsFilter: ProductState
        commonFilter: CommonFilterType
      }) => ({
        url: `/search/buyer_alerts?page=${productsFilter.pageNo}`,
        method: 'POST',
        body: formatProductFilterData(productsFilter, commonFilter),
      }),
      transformResponse: async (responseData) => {
        return responseData
      },
    }),
    getSalesOffer: builder.query({
      query: ({
        productsFilter,

        commonFilter,
      }: {
        productsFilter: ProductState
        commonFilter: CommonFilterType
      }) => ({
        url: `/search/sales_offers?page=${productsFilter.pageNo}`,
        method: 'POST',
        body: formatProductFilterData(productsFilter, commonFilter),
      }),
      transformResponse: async (responseData) => {
        return responseData
      },
    }),
    getNewsSearch: builder.query({
      query: ({
        newsFilter,
        commonFilter,
      }: {
        newsFilter: NewsFilterState
        commonFilter: CommonFilterType
      }) => ({
        url: `/search/news?page=${newsFilter.pageNo}`,
        method: 'POST',
        body: formatNewsPayload(newsFilter, commonFilter),
      }),
      transformResponse: async (responseData) => {
        return responseData
      },
    }),
    getEventSearch: builder.query({
      query: ({
        eventFilter,
        commonFilter,
      }: {
        eventFilter: EventFilterState
        commonFilter: CommonFilterType
      }) => ({
        url: `/search/events?page=${eventFilter.pageNo}`,
        method: 'POST',
        body: formatEventFilterData(eventFilter, commonFilter),
      }),
      transformResponse: async (responseData) => {
        return responseData
      },
    }),
  }),
})

export const {
  useGetSearchQuery,
  useGetCompanySearchQuery,
  useGetProductSearchQuery,
  useGetNewsSearchQuery,
  useGetBuyerAlertsQuery,
  useGetSalesOfferQuery,
  useGetEventSearchQuery,
} = searchSlice
